<script setup>
import { computed, useTemplateRef } from "vue";
import SvgUse from "../../components/SvgUse.vue";
import OptionRow from "../OptionRow.vue";
import { useRadioOptionProps } from "../composables/props";
import { useOrderForm } from "../../modules/orderModule";
import { capitalize } from "../../helpers";
import OrderOption from "../classes/OrderOption";

const props = defineProps({
  option: {
    type: [Object],
    validator(value) {
      return value instanceof OrderOption;
    },
    required: true,
  },
  ...useRadioOptionProps(),
});

const form = useOrderForm();
const row = useTemplateRef("row");
const id = computed(() => {
  return Symbol.keyFor(props.option.id);
});

const classes = computed(() => {
  return {
    deactive: props.disabled,
  };
});

form.addOption({
  option: props.option,
  type: "radio",
});

const preloaded = import.meta.env.SSR
  ? global.preload[props.option.key]
  : window.preload[props.option.key];

const urlParamsObject = import.meta.env.SSR
  ? {}
  : Object.fromEntries(new URLSearchParams(window.location.search).entries());

if (preloaded) {
  form.updateItem({
    option: props.option,
    value: preloaded,
  });
  form.touchItem({ option: props.option, programmatically: true });
} else if (!form.isEditing() && urlParamsObject[props.option.key]) {
  form.updateItem({
    option: props.option,
    value: urlParamsObject[props.option.key],
  });
} else if (!form.isEditing() && urlParamsObject[props.option.key] === undefined && props.initial) {
  form.selectItem(props.option);
}

const checked = computed(() => {
  return form.isChecked(props.option);
});

const openTooltip = () => {
  form.openTooltip({
    title: props.iconTitle,
    content: props.iconContent,
    anchor: row.value.container,
  });
};

const update = () => {
  form.selectItem(props.option);
  form.touchItem({ option: props.option });
};
</script>

<template>
  <OptionRow v-show="!hidden" v-bind="$props" ref="row">
    <div class="radio fancy-radio">
      <input
        :id="id"
        type="radio"
        :value="option.value"
        :name="option.key"
        :checked="checked"
        @change="update"
      />
      <label class="fancy-control-ui" :for="id" />
      <label :class="classes" :for="id">
        {{ capitalize(option.label) }}
        <template v-if="props.icon && !props.iconTitle">
          <span class="tailwind" style="display: inline-block">
            <svg-use
              :id="props.icon"
              :type="props.iconType"
              :class="props.iconClass"
              :style="{ fill: props.iconColor }"
            />
          </span>
        </template>
        <span v-if="props.new" style="font-size: 75%; vertical-align: top; color: #ff367d;">nieuw</span>
      </label>
      <template v-if="props.icon && props.iconTitle">
        <a
          href="javascript:;"
          class="tailwind"
          style="display: inline-block; border: 0"
          @click="openTooltip"
        >
          <svg-use
            :id="props.icon"
            :type="props.iconType"
            :class="props.iconClass"
            :style="{ fill: props.iconColor }"
          />
        </a>
      </template>
    </div>
  </OptionRow>
</template>
