<script setup>
import { computed } from "vue";
import { useOrderForm } from "../../modules/orderModule";
import OrderOption from "../classes/OrderOption";

const props = defineProps({
  option: {
    type: [Object],
    validator(value) {
      return value instanceof OrderOption;
    },
    required: true,
  },
  type: {
    validator(value) {
      return ["boolean", "bit", "string", "integer", "float"].includes(value);
    },
    required: true,
  },
  initial: {
    type: [String, Number, Boolean],
    validator(value, props) {
      if (["integer", "float"].includes(props.type)) {
        return typeof value === "number";
      }
      if (props.type === "string") {
        return typeof value === "string";
      }
      if (props.type === "bit") {
        return [0, 1].includes(value);
      }
      if (props.type === "boolean") {
        return typeof value === "boolean";
      }

      return false;
    },
    default: null,
  },
});

const parsePreloadedValue = (value) => {
  if (props.type === "boolean") {
    if (value === "false" || value === "0") {
      return false;
    } else if (value === "true" || value === "1") {
      return true;
    }

    return value ? true : false;
  } else if (props.type === "bit") {
    return value == "1" ? 1 : 0;
  } else if (props.type === "integer") {
    return parseInt(value);
  } else if (props.type === "float") {
    return parseFloat(value);
  } else {
    return value.toString();
  }
};

const form = useOrderForm();

form.addOption({
  option: props.option,
  type: "hidden",
});

const preloaded = import.meta.env.SSR
  ? global.preload[props.option.key]
  : window.preload[props.option.key];

const urlParamsObject = import.meta.env.SSR
  ? {}
  : Object.fromEntries(new URLSearchParams(window.location.search).entries());

if (preloaded) {
  form.updateItem({
    option: props.option,
    value: parsePreloadedValue(preloaded),
  });
  form.touchItem({ option: props.option, programmatically: true });
} else if (!form.isEditing() && urlParamsObject[props.option.key]) {
  form.updateItem({
    option: props.option,
    value: parsePreloadedValue(urlParamsObject[props.option.key]),
  });
} else if (!form.isEditing()) {
  form.updateItem({
    option: props.option,
    value: props.initial,
  });
}

const id = computed(() => {
  return Symbol.keyFor(props.option.id);
});

const value = computed(() => {
  return form.getValue(props.option);
});
</script>

<template>
  <tbody>
    <input :id="id" :value="value" :name="option.key" type="hidden" />
  </tbody>
</template>
