import isEqual from "lodash.isequal";
import { useStore } from "vuex";
import OrderOption from "../order/classes/OrderOption";
import SizeCustomOption from "../order/options/SizeCustomOption.vue";
import { CustomWidthOption, CustomHeightOption } from "../order/options/SizeCustomOption.vue";
import ActionOption from "../order/options/ActionOption.vue";
import OrderIdOption from "../order/options/OrderIdOption.vue";

export default {
  namespaced: true,
  state: () => ({
    initialized: false,
    analyzing: false,
    changes: {
      user: 0,
      programmatically: 0,
      url: 0,
      total: 0,
    },
    delivery_terms_changed: false,
    change_made_during_upload: false,
    tooltip: {
      open: false,
      title: "",
      content: "",
      anchor: null,
    },
    form: {},
    fileinfo: {},
    checks: [],
    options: [],
    files: [],
    fileProgress: [],
    calculation: {},
    errors: [],
    warnings: [],
    notifications: [],
  }),
  actions: {
    selectItem({ commit }, option) {
      commit("updateItem", { option, value: option.value });
    },
  },
  mutations: {
    setInitialized(state) {
      state.initialized = true;
    },
    setAnalyzing(state, payload) {
      state.analyzing = payload;
    },
    openTooltip(state, payload) {
      state.tooltip.title = payload.title;
      state.tooltip.content = payload.content;
      state.tooltip.anchor = payload.anchor;
      state.tooltip.open = true;
    },
    closeTooltip(state) {
      state.tooltip.open = false;
    },
    updateItem(state, payload) {
      if (!(payload.option instanceof OrderOption)) {
        throw new Error("Option must be an instance of OrderOption");
      }

      if (state.initialized && state.form[payload.option.key] != payload.value) {
        console.log({
          key: payload.option.key,
          from: state.form[payload.option.key],
          to: payload.value,
        });
      }
      state.form[payload.option.key] = payload.value;
    },
    deleteItem(state, option) {
      if (!(option instanceof OrderOption)) {
        throw new Error("Option must be an instance of OrderOption");
      }

      if (state.initialized && state.form[option.key] !== undefined) {
        console.log({ key: option.key, from: state.form[option.key], to: undefined });
      }
      delete state.form[option.key];
    },
    touchItem(state, payload) {
      if (!(payload.option instanceof OrderOption)) {
        throw new Error("Option must be an instance of OrderOption");
      }

      const option = state.options.find((o) => o.id === payload.option.id);
      if (!option) {
        return;
      }

      if (payload.programmatically !== true && state.delivery_terms_changed === true) {
        console.log({ delivery_terms_changed: false });
        state.delivery_terms_changed = false;
      }

      if (payload.programmatically !== true && state.fileProgress.length > 0) {
        console.log({ change_made_during_upload: true });
        state.change_made_during_upload = true;
      }

      if (state.initialized) {
        state.changes.total++;
        if (payload.programmatically !== true) {
          state.changes.user++;
        } else {
          state.changes.programmatically++;
        }
        if (option.updateUrl) {
          state.changes.url++;
        }
      }

      if (!option.touched) {
        option.touched = payload.programmatically === true ? 2 : 1;
        if (state.initialized) {
          console.log({ key: option.key, touched: payload.programmatically === true ? 2 : 1 });
        }
      }
    },
    setFileinfo(state, payload) {
      if (isEqual(payload, state.fileinfo)) {
        return;
      }

      Object.keys(state.fileinfo).forEach((key) => delete state.fileinfo[key]);
      Object.keys(payload).forEach((key) => (state.fileinfo[key] = payload[key]));
    },
    deleteFileinfo(state) {
      if (Object.keys(state.fileinfo).length === 0) {
        return;
      }

      Object.keys(state.fileinfo).forEach((key) => delete state.fileinfo[key]);
    },
    setPriceDifference(state, payload) {
      const option = state.options.find((o) => o.id === payload.option.id);

      if (option) {
        option.difference = payload.difference;
      }
    },
    clearPriceDifference(state) {
      for (const option of state.options) {
        option.difference = 0;
      }
    },
    addOption(state, payload) {
      payload.option.type = payload.type;
      state.options.push(payload.option);
    },
    addOptionCheck(state, { option, section, fn }) {
      const duplicate = state.checks.find((item) => {
        if (!item.option) {
          return false;
        }
        if (option.id !== item.option.id) {
          return false;
        }
        if (item.section !== section) {
          return false;
        }
        if (item.fn.toString() !== fn.toString()) {
          return false;
        }

        return true;
      });

      if (duplicate) {
        return;
      }

      state.checks.push({ option, section, fn });
    },
    addProductCheck(state, fn) {
      const duplicate = state.checks.find((item) => {
        if (item.fn.toString() !== fn.toString()) {
          return false;
        }

        return true;
      });

      if (duplicate) {
        return;
      }

      state.checks.push({ fn });
    },
    setCalculation(state, payload) {
      if (isEqual(payload, state.calculation)) {
        return;
      }

      if (
        state.delivery_terms_changed === false &&
        payload.compare_specs_hash &&
        state.calculation.compare_specs_hash &&
        state.calculation.compare_specs_hash !== payload.compare_specs_hash
      ) {
        console.log({ delivery_terms_changed: true });
        state.delivery_terms_changed = true;
      }

      Object.keys(state.calculation).forEach((key) => delete state.calculation[key]);
      Object.keys(payload).forEach((key) => (state.calculation[key] = payload[key]));
    },
    clearCalculation(state) {
      if (Object.keys(state.calculation).length === 0) {
        return;
      }

      Object.keys(state.calculation).forEach((key) => delete state.calculation[key]);
    },
    addError(state, payload) {
      state.errors.push(payload);
    },
    removeError(state, payload) {
      state.errors.splice(state.errors.indexOf(payload), 1);
    },
    setErrors(state, payload) {
      if (isEqual(payload, state.errors)) {
        return;
      }

      state.errors.splice(0, state.errors.length, ...payload);
    },
    clearErrors(state) {
      if (state.errors.length === 0) {
        return;
      }

      state.errors.length = 0;
    },
    addWarning(state, payload) {
      state.warnings.push(payload);
    },
    removeWarning(state, payload) {
      state.warnings.splice(state.warnings.indexOf(payload), 1);
    },
    setWarnings(state, payload) {
      if (isEqual(payload, state.warnings)) {
        return;
      }

      state.warnings.splice(0, state.warnings.length, ...payload);
    },
    clearWarnings(state) {
      if (state.warnings.length === 0) {
        return;
      }

      state.warnings.length = 0;
    },
    addNotification(state, payload) {
      state.notifications.push(payload);
    },
    removeNotification(state, payload) {
      state.notifications.splice(state.notifications.indexOf(payload), 1);
    },
    setNotifications(state, payload) {
      if (isEqual(payload, state.notifications)) {
        return;
      }

      state.notifications.splice(0, state.notifications.length, ...payload);
    },
    clearNotifications(state) {
      if (state.notifications.length === 0) {
        return;
      }

      state.notifications.length = 0;
    },
    setFiles(state, payload) {
      if (isEqual(payload, state.files)) {
        return;
      }

      Object.keys(state.files).forEach((key) => delete state.files[key]);
      Object.keys(payload).forEach((key) => (state.files[key] = payload[key]));
    },
    clearFiles(state) {
      if (Object.keys(state.files).length === 0) {
        return;
      }

      Object.keys(state.files).forEach((key) => delete state.files[key]);
    },
    addFile(state, payload) {
      state.files.push(payload);
    },
    removeFile(state, name) {
      const existing = state.files.find((item) => item.name === name);
      if (existing) {
        state.files.splice(state.files.indexOf(existing), 1);
      }
    },
    setFileProgress(state, payload) {
      const existing = state.fileProgress.findIndex((item) => item.name === payload.name);
      if (existing !== -1) {
        state.fileProgress[existing] = payload;
      } else {
        state.fileProgress.push(payload);
      }
    },
    clearFileProgress(state, name) {
      const existing = state.fileProgress.find((item) => item.name === name);
      if (existing) {
        state.fileProgress.splice(state.fileProgress.indexOf(existing), 1);
      }
      if (state.fileProgress.length === 0 && state.change_made_during_upload) {
        console.log({ change_made_during_upload: false });
        state.change_made_during_upload = false;
      }
    },
  },
  getters: {
    getValue:
      (state) =>
      (option, fallback = null) => {
        if (!(option instanceof OrderOption)) {
          throw new Error("Option must be an instance of OrderOption");
        }

        return state.form[option.key] ?? fallback;
      },
    getFileinfo: (state) => () => {
      return state.fileinfo;
    },
    getCalculation: (state) => () => {
      return state.calculation;
    },
    getErrors: (state) => () => {
      return state.errors;
    },
    getWarnings: (state) => () => {
      return state.warnings;
    },
    getNotifications: (state) => () => {
      return state.notifications;
    },
    hasErrors: (state) => () => {
      return state.errors.length > 0;
    },
    hasError: (state, getters) => (option) => {
      option = state.options.find((o) => o.id === option.id);
      if (!option) {
        return false;
      }

      return state.errors.some((e) => e.key === option.id);
    },
    hasWarnings: (state) => () => {
      return state.warnings.length > 0;
    },
    hasNotifications: (state) => () => {
      return state.notifications.length > 0;
    },
    isChecked: (state, getters) => (option) => {
      if (option.value === undefined || option.value === null) {
        throw new Error("Option doesnt have a value, so it cannot be checked");
      }

      return getters.getValue(option) === option.value;
    },
    isNotChecked: (state, getters) => (option) => {
      if (option.value === undefined || option.value === null) {
        throw new Error("Option doesnt have a value, so it cannot be checked");
      }

      return getters.getValue(option) !== option.value;
    },
    isAvailable: (state) => (option) => {
      option = state.options.find((o) => o.id === option.id);

      return option ? true : false;
    },
    isEmpty: (state, getters) => (option) => {
      return getters.getValue(option) === null || getters.getValue(option) === undefined;
    },
    isTouched: (state) => (option) => {
      option = state.options.find((o) => o.id === option.id);

      return option.touched > 0 ? true : false;
    },
    isInt: (state, getters) => (option) => {
      const val = getters.getValue(option);

      return Number.isInteger(val) && val < Number.MAX_SAFE_INTEGER;
    },
    isString: (state, getters) => (option) => {
      const val = getters.getValue(option);

      return typeof val === "string";
    },
    hasFileinfo: (state) => () => {
      const { doc: { page_count = 0 } = {} } = state.fileinfo;

      return page_count > 0;
    },
    size: (state, getters) => () => {
      const size = getters.getValue(SizeCustomOption);
      const sizeWidth = getters.getValue(CustomWidthOption);
      const sizeHeight = getters.getValue(CustomHeightOption);
      const { doc: { orientation = null, highest_orientation = null } = {} } = state.fileinfo;

      let height = null;
      let width = null;
      let custom = false;
      let format = size;

      const sizes = import.meta.env.SSR ? global.constants.size : window.constants.size;
      if (sizes[size]) {
        height = sizes[size].height;
        width = sizes[size].width;
      } else if (size === "custom" || size === "square") {
        custom = true;
        height = sizeHeight;
        width = sizeWidth;
      } else if (size.includes("x")) {
        [height, width] = size.split("x").sort(function (a, b) {
          if (orientation == "portrait" || highest_orientation == "portrait") {
            return b - a;
          } else {
            return a - b;
          }
        });
      } else if (size.indexOf("o") === 0) {
        height = size.replace("o", "");
        width = size.replace("o", "");
      }

      return { height: height, width: width, custom: custom, format: format };
    },
    getPriceDifference: (state) => (option) => {
      option = state.options.find((o) => o.id === option.id);

      return option ? option.difference : null;
    },
    isEditing: (state, getters) => () => {
      return ["change", "admin_change", "favorite"].includes(getters.getValue(ActionOption));
    },
    isEditingFavorite: (state, getters) => () => {
      return ["favorite"].includes(getters.getValue(ActionOption));
    },
    isFirstArticle: (state, getters) => () => {
      return !getters.getValue(OrderIdOption);
    },
    isUploading: (state) => () => {
      return state.fileProgress.some((p) =>
        ["uploading", "processing", "finished"].includes(p.status),
      );
    },
    isAnalyzing: (state) => () => {
      return state.analyzing;
    },
    isInitialized: (state) => () => {
      return state.initialized;
    },
  },
};

export function useOrderForm() {
  const store = useStore();

  return {
    getValue: store.getters["order/getValue"],
    getFileinfo: store.getters["order/getFileinfo"],
    getCalculation: store.getters["order/getCalculation"],
    getErrors: store.getters["order/getErrors"],
    getWarnings: store.getters["order/getWarnings"],
    getNotifications: store.getters["order/getNotifications"],
    hasErrors: store.getters["order/hasErrors"],
    hasError: store.getters["order/hasError"],
    hasWarnings: store.getters["order/hasWarnings"],
    hasNotifications: store.getters["order/hasNotifications"],
    getPriceDifference: store.getters["order/getPriceDifference"],
    isChecked: store.getters["order/isChecked"],
    isNotChecked: store.getters["order/isNotChecked"],
    isAvailable: store.getters["order/isAvailable"],
    isEmpty: store.getters["order/isEmpty"],
    isTouched: store.getters["order/isTouched"],
    isInt: store.getters["order/isInt"],
    isString: store.getters["order/isString"],
    isEditing: store.getters["order/isEditing"],
    isEditingFavorite: store.getters["order/isEditingFavorite"],
    isFirstArticle: store.getters["order/isFirstArticle"],
    isUploading: store.getters["order/isUploading"],
    isInitialized: store.getters["order/isInitialized"],
    isAnalyzing: store.getters["order/isAnalyzing"],
    hasFileinfo: store.getters["order/hasFileinfo"],
    size: store.getters["order/size"],
    setInitialized: () => store.commit("order/setInitialized"),
    setAnalyzing: (payload) => store.commit("order/setAnalyzing", payload),
    updateItem: (payload) => store.commit("order/updateItem", payload),
    deleteItem: (option) => store.commit("order/deleteItem", option),
    selectItem: (option) => store.dispatch("order/selectItem", option),
    touchItem: (option) => store.commit("order/touchItem", option),
    openTooltip: (payload) => store.commit("order/openTooltip", payload),
    setFileinfo: (payload) => store.commit("order/setFileinfo", payload),
    deleteFileinfo: () => store.commit("order/deleteFileinfo"),
    addOption: (payload) => store.commit("order/addOption", payload),
    setPriceDifference: (payload) => store.commit("order/setPriceDifference", payload),
    clearPriceDifference: () => store.commit("order/clearPriceDifference"),
    addOptionCheck: (payload) => store.commit("order/addOptionCheck", payload),
    addProductCheck: (payload) => store.commit("order/addProductCheck", payload),
    setCalculation: (payload) => store.commit("order/setCalculation", payload),
    clearCalculation: (payload) => store.commit("order/clearCalculation", payload),
    addError: (payload) => store.commit("order/addError", payload),
    removeError: (payload) => store.commit("order/removeError", payload),
    setErrors: (payload) => store.commit("order/setErrors", payload),
    clearErrors: (payload) => store.commit("order/clearErrors", payload),
    addWarning: (payload) => store.commit("order/addWarning", payload),
    removeWarning: (payload) => store.commit("order/removeWarning", payload),
    setWarnings: (payload) => store.commit("order/setWarnings", payload),
    clearWarnings: (payload) => store.commit("order/clearWarnings", payload),
    addNotification: (payload) => store.commit("order/addNotification", payload),
    removeNotification: (payload) => store.commit("order/removeNotification", payload),
    setNotifications: (payload) => store.commit("order/setNotifications", payload),
    clearNotifications: (payload) => store.commit("order/clearNotifications", payload),
    setFiles: (payload) => store.commit("order/setFiles", payload),
    clearFiles: () => store.commit("order/clearFiles"),
    addFile: (payload) => store.commit("order/addFile", payload),
    removeFile: (payload) => store.commit("order/removeFile", payload),
    setFileProgress: (payload) => store.commit("order/setFileProgress", payload),
    clearFileProgress: (name) => store.commit("order/clearFileProgress", name),
  };
}
