<script setup>
import { computed } from "vue";
import OptionRow from "../OptionRow.vue";
import { useIntegerOptionProps } from "../composables/props";
import { useOrderForm } from "../../modules/orderModule";
import OrderOption from "../classes/OrderOption";

const props = defineProps({
  option: {
    type: [Object],
    validator(value) {
      return value instanceof OrderOption;
    },
    required: true,
  },
  ...useIntegerOptionProps(),
});

const form = useOrderForm();

const id = computed(() => {
  return Symbol.keyFor(props.option.id);
});

form.addOption({
  option: props.option,
  type: "integer",
});

const preloaded = import.meta.env.SSR
  ? global.preload[props.option.key]
  : window.preload[props.option.key];

const urlParamsObject = import.meta.env.SSR
  ? {}
  : Object.fromEntries(new URLSearchParams(window.location.search).entries());

if (preloaded) {
  form.updateItem({
    option: props.option,
    value: parseInt(preloaded),
  });
  form.touchItem({ option: props.option, programmatically: true });
} else if (!form.isEditing() && urlParamsObject[props.option.key]) {
  form.updateItem({
    option: props.option,
    value: parseInt(urlParamsObject[props.option.key]),
  });
} else if (!form.isEditing() && urlParamsObject[props.option.key] === undefined && props.initial) {
  form.updateItem({
    option: props.option,
    value: props.initial,
  });
}

const update = (event) => {
  if (!isNaN(parseInt(event.target.value))) {
    form.updateItem({
      option: props.option,
      value: parseInt(event.target.value),
    });
  } else {
    form.deleteItem(props.option);
  }
  form.touchItem({ option: props.option });
};

const value = computed(() => {
  return form.getValue(props.option);
});
</script>

<template>
  <OptionRow v-bind="$props">
    <input
      :id="id"
      :value="value"
      :name="option.key"
      type="number"
      autocomplete="off"
      inputmode="numeric"
      :disabled="disabled"
      style="max-width: 70px"
      @input="update"
    />
  </OptionRow>
</template>
