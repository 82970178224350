<script>
import OrderOption from "../classes/OrderOption";

export const CustomDiameterOption = new OrderOption({
  key: "size_custom_diameter",
  type: "integer",
  updateUrl: true,
});
</script>
<script setup>
import { computed, watch } from "vue";
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import { FatalFormError } from "../composables/errors";
import { useSetup } from "../composables/option";
import { isOneOf, isRequired } from "../checks/basic";
import { useOrderForm } from "../../modules/orderModule";
import { __, sprintf } from "../composables/lang";

defineOptions(
  new OrderOption({
    key: "size",
    value: "circular",
    label: __("Rond (eigen formaat)"),
    updateUrl: true,
  }),
);

const props = defineProps(useRadioOptionProps());
const { option, check, disabled, tooltipTitle, tooltipContent } = useSetup(props);
const form = useOrderForm();

const diameterId = computed(() => {
  return Symbol.keyFor(CustomDiameterOption.id);
});

form.addOption({
  option: CustomDiameterOption,
  type: "integer",
});

for (const option of [CustomDiameterOption]) {
  const preloaded = import.meta.env.SSR ? global.preload[option.key] : window.preload[option.key];
  const urlParamsObject = import.meta.env.SSR
    ? {}
    : Object.fromEntries(new URLSearchParams(window.location.search).entries());

  if (preloaded) {
    form.updateItem({
      option,
      value: parseInt(preloaded),
    });
    form.touchItem({ option, programmatically: true });
  } else if (!form.isEditing() && urlParamsObject[option.key]) {
    form.updateItem({
      option,
      value: parseInt(urlParamsObject[option.key]),
    });
  }
}

const update = (option, event) => {
  if (!isNaN(parseInt(event.target.value))) {
    form.updateItem({
      option,
      value: parseInt(event.target.value),
    });
  } else {
    form.updateItem({
      option,
      value: null,
    });
  }
  form.touchItem({ option });
};

check(isRequired());
check(isOneOf(props.choices));

form.addOptionCheck({
  option,
  section: `${props.section}: ${__("diameter")}`,
  fn: (option, form) => {
    if (!option.isChecked()) {
      return;
    }

    if (form.isEmpty(CustomDiameterOption)) {
      throw new FatalFormError(__("Er moet een waarde ingevuld worden"));
    }

    const min = 100;
    const max = 1300;

    const val = form.getValue(CustomDiameterOption, 0);
    if (val < min) {
      throw new FatalFormError(sprintf(__("Is te klein, minimale afmeting is %smm"), min));
    } else if (val > max) {
      throw new FatalFormError(sprintf(__("Is te groot, maximale afmeting is %smm"), max));
    }
  },
});

const checked = computed(() => {
  return form.isChecked(option);
});

const diameterValue = computed(() => {
  return form.getValue(CustomDiameterOption);
});

watch(checked, (newValue) => {
  if (newValue) {
    form.updateItem({ option: CustomDiameterOption, value: null });
  } else {
    form.deleteItem(CustomDiameterOption);
  }
  form.touchItem({ option: CustomDiameterOption, programmatically: true });
});
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :option="option"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
  />
  <tr v-if="checked" class="tr_size_custom_diameter">
    <td width="24%"></td>
    <td width="57%">
      <div class="custom-size-field">
        <input
          :id="diameterId"
          :value="diameterValue"
          type="number"
          autocomplete="off"
          placeholder="b"
          style="max-width: 70px"
          @input="(event) => update(CustomDiameterOption, event)"
        />
        mm
      </div>
    </td>
    <td width="19%"></td>
  </tr>
</template>
