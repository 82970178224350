<script setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import * as products from "./products";
import { FormError, FatalFormError, InfoFormNotification, InfoFormWarning } from "./composables/errors";
import FatalError from "./messages/FatalError.vue";
import OrderPrices from "./OrderPrices.vue";
import OrderTooltip from "./OrderTooltip.vue";
import { useStore } from "vuex";
import SvgUse from "../components/SvgUse.vue";
import { useOrderForm } from "../modules/orderModule";
import debounce from "lodash.debounce";
import { __ } from "./composables/lang";
import { formatMoney } from "./composables/helpers";
import InfoWarning from "./messages/InfoWarning.vue";
import OrderDeliveryTerms from "./OrderDeliveryTerms.vue";
import Preview from "../preview/Preview.vue";
import DefaultDocOption from "./options/DefaultDocOption.vue";
import FileNamesOption from "./options/FileNamesOption.vue";
import InfoNotification from "./messages/InfoNotification.vue";
import { useEmitter } from "./composables/emitter";
import LoaderSpinner from "../components/LoaderSpinner.vue";
import ActionOption from "./options/ActionOption.vue";
import IdOption from "./options/IdOption.vue";
import ProductOption from "./options/ProductOption.vue";
import OrderIdOption from "./options/OrderIdOption.vue";
import DeliveryMethodHiddenOption from "./options/DeliveryMethodHiddenOption.vue";
import DeliveryOptionHiddenOption from "./options/DeliveryOptionHiddenOption.vue";
import ProductionMethodHiddenOption from "./options/ProductionMethodHiddenOption.vue";
import OrderFileList from "./OrderFileList.vue";
import AnalyzingMessage from "./messages/AnalyzingMessage.vue";
import ProcessingMessage from "./messages/ProcessingMessage.vue";

const props = defineProps({
  product: {
    type: String,
    default: "",
  },
});

const form = useOrderForm();
const store = useStore();
const { emit, on, off } = useEmitter();

const showDeliveryConditions = ref(false);
const submitting = ref(false);

const component = computed(() => {
  for (const [, entry] of Object.entries(products)) {
    if (entry.slug === props.product) {
      return entry;
    }
    let aliases = entry.aliases ?? [];
    if (aliases.find((item) => item.slug === props.product)) {
      return entry;
    }
  }

  return null;
});

const title = computed(() => {
  for (const [, entry] of Object.entries(products)) {
    if (entry.slug === props.product) {
      return entry.title;
    }

    let aliases = entry.aliases ?? [];
    let alias = aliases.find((item) => item.slug === props.product);
    if (alias) {
      return alias.title;
    }
  }

  return null;
});

const messageComponent = (message) => {
  if (message instanceof FormError) {
    return FatalError;
  }
  if (message instanceof FatalFormError) {
    return FatalError;
  }
  if (message instanceof InfoFormWarning) {
    return InfoWarning;
  }
  if (message instanceof InfoFormNotification) {
    return InfoNotification;
  }

  return null;
};

const calculation = computed(() => {
  if (Object.keys(store.state.order.calculation).length === 0) {
    return null;
  }

  let calculation = store.state.order.calculation;
  calculation.price_total = parseFloat(calculation.price_total);
  calculation.price_total_tax = (parseFloat(calculation.price_total)) * (parseFloat(calculation.taxrate) / 100);
  calculation.price_total_incl = calculation.price_total + calculation.price_total_tax;

  return calculation;
});

const submit = async (force = false) => {
  let fatalErrors = form.getErrors().filter((error) => error instanceof FatalFormError);
  if (fatalErrors.length > 0) {
    console.log('Submit canceled, fatal errors');
    return;
  }

  if (!force && !form.getValue(FileNamesOption) && !form.isEditing()) {
    emit("open-no-files-dialog");
    return;
  }

  console.log("AJAX call: article store");

  let formData = new FormData();
  if (!form.isEditing()) {
    formData.append("force_new_article", true);
  }

  for (const [key, value] of Object.entries(store.state.order.form)) {
    const option = store.state.order.options.find((option) => {
      if (option.value !== undefined) {
        return option.key === key && option.value === value;
      }

      return option.key === key;
    });
    if (option && !option.excludeFromSave) {
      formData.append(key, value !== null ? value : "");
    }
  }

  submitting.value = true;

  let filteredNotification = form
    .getNotifications()
    .filter((i) => !["cart-add-success", "cart-update-success"].includes(i.key));

  form.setNotifications(filteredNotification);

  if (!form.isEditing()) {
    emit("cart-add", {
      url: store.getters.route("shoppingcart") + "?action=add",
      formData,
    });
  } else {
    let articleID = store.state.order.form.id;
    emit("cart-update", {
      url: store.getters.route("shoppingcart") + `?articleID=${articleID}&action=update`,
      formData,
    });
  }
};

onMounted(() => {
  form.setInitialized();
  on("accept-no-files", () => {
    submit(true);
  });
  on(
    "cart-add-success",
    ({ id, order_id, delivery_method, delivery_option, production_method }) => {
      submitting.value = false;
      form.addNotification(
        new InfoFormNotification(__("Artikel samenstelling is toegevoegd."), {
          key: "cart-add-success",
          position: "top",
          icon: "cart-shopping",
        }),
      );

      form.updateItem({ option: ActionOption, value: "change" });
      form.updateItem({ option: IdOption, value: id });
      form.updateItem({ option: OrderIdOption, value: order_id });

      const url = new URL(window.location.origin + window.location.pathname);
      url.searchParams.set("product", form.getValue(ProductOption));
      url.searchParams.set("articleID", form.getValue(IdOption));
      url.searchParams.set("action", form.getValue(ActionOption));
      window.history.replaceState({}, "", url.href);

      nextTick(() => {
        form.updateItem({ option: DeliveryMethodHiddenOption, value: delivery_method });
        form.updateItem({ option: DeliveryOptionHiddenOption, value: delivery_option });
        form.updateItem({ option: ProductionMethodHiddenOption, value: production_method });
      });
    },
  );
  on("cart-update-success", () => {
    submitting.value = false;
    form.addNotification(
      new InfoFormNotification(__("Artikel samenstelling is opgeslagen."), {
        key: "cart-update-success",
        position: "top",
        icon: "cart-shopping",
      }),
    );
  });
  on("cart-remove-success", ({ id }) => {
    if (id != form.getValue(IdOption)) {
      return;
    }

    form.updateItem({ option: ActionOption, value: null });
    form.updateItem({ option: IdOption, value: null });

    const url = new URL(window.location.origin + window.location.pathname);
    url.searchParams.delete("articleID");
    url.searchParams.delete("action");
    window.history.replaceState({}, "", url.href);

    let filteredNotification = form
      .getNotifications()
      .filter((i) => !["cart-add-success", "cart-update-success"].includes(i.key));

    form.setNotifications(filteredNotification);
  });
  on("cart-add-fail", () => {
    console.warn("add to cart failed");
    submitting.value = false;
  });
  on("cart-update-fail", () => {
    console.warn("updating cart item failed");
    submitting.value = false;
  });
});

onUnmounted(() => {
  off("cart-add-success");
  off("cart-update-success");
  off("cart-remove-success");
  off("cart-add-fail");
  off("cart-update-fail");
});

watch(
  [() => form.isInitialized(), store.state.order.form, store.state.order.fileinfo],
  debounce(async ([initialized]) => {
    if (!initialized) {
      return;
    }

    let errorsTemp = [];
    let warningsTemp = [];

    if (component.value === null) {
      errorsTemp.push(new FatalFormError("Onbekend", { key: "product", section: "Product" }));
    }
    console.log("Running all checks");
    for (const check of store.state.order.checks) {
      try {
        if (check.option) {
          check.fn(
            new Proxy(check.option, {
              get(target, prop) {
                if (form[prop] && typeof form[prop] === "function") {
                  return (...args) => form[prop](target, ...args);
                }
                return Reflect.get(...arguments);
              },
            }),
            form,
            form.getFileinfo(),
          );
        } else {
          check.fn(form, form.getFileinfo());
        }
      } catch (err) {
        if (err instanceof FormError) {
          err.key = check.option?.id;
          err.section = check.section;
          errorsTemp.push(err);
        } else if (err instanceof FatalFormError) {
          err.key = check.option?.id;
          err.section = check.section;
          errorsTemp.push(err);
        } else if (err instanceof InfoFormWarning) {
          err.key = check.option?.id;
          err.section = check.section;
          warningsTemp.push(err);
        } else if (err instanceof InfoFormNotification) {
          err.key = check.option?.id;
          err.section = check.section;
          let existing = form.getNotifications().find((i) => i.message === err.message);
          if (!existing) {
            form.addNotification(err);
          }
        } else {
          throw err;
        }
      }
    }

    form.setErrors(errorsTemp);
    form.setWarnings(warningsTemp);
  }, 10),
);

watch(
  [form.getWarnings(), () => store.state.order.delivery_terms_changed],
  ([warnings, deliveryTermsChanged]) => {
    const existing = warnings.find((i) => i.key === "delivery_terms");

    if (existing && deliveryTermsChanged) {
      return;
    }
    if (!existing && !deliveryTermsChanged) {
      return;
    }
    if (existing && !deliveryTermsChanged) {
      form.removeWarning(existing);
    }
    if (!existing && deliveryTermsChanged) {
      form.addWarning(
        new InfoFormWarning(__("De {cta} zijn gewijzigd"), {
          key: "delivery_terms",
          cta: [
            {
              text: __("aanlevervoorwaarden"),
              fn: () => {
                showDeliveryConditions.value = true;
              },
            },
          ],
        }),
      );
    }
  },
);

watch(
  [form.getWarnings(), () => store.state.order.change_made_during_upload],
  ([warnings, changesMade]) => {
    const existing = warnings.find((i) => i.key === "changes_during_uploading");

    if (existing && changesMade) {
      return;
    }
    if (!existing && !changesMade) {
      return;
    }
    if (existing && !changesMade) {
      form.removeWarning(existing);
    }
    if (!existing && changesMade) {
      form.addWarning(
        new InfoFormWarning(
          __(
            "<b>Bezig met uploaden</b> Er is nog een bestand aan het uploaden, je kunt pas verder als deze is afgerond",
          ),
          {
            key: "changes_during_uploading",
          },
        ),
      );
    }
  },
);

watch(
  () => store.state.order.form.filenames,
  () => {
    const options = store.state.order.options.filter((option) => {
      return option.touched === 2;
    });

    options.forEach((option, index) => {
      store.state.order.options[index].touched = 0;
    });
  },
);

watch(
  () => store.state.order.changes.user,
  () => {
    form.clearNotifications();
  },
);

watch(
  [() => form.isInitialized(), () => store.state.order.changes.url],
  ([initialized, changes]) => {
    if (!initialized || changes === 0 || form.isEditing()) {
      return;
    }

    let params = {};
    for (const [key, value] of Object.entries(store.state.order.form)) {
      const option = store.state.order.options.find((option) => option.key === key);
      if (option.updateUrl === true && value !== null && value !== undefined && value !== "") {
        params[key] = value;
      }
    }
    const checkboxes = store.state.order.options.filter((option) => option.type === "checkbox");
    for (const checkbox of checkboxes) {
      if (checkbox.key in params) {
        continue;
      }
      params[checkbox.key] = "";
    }

    const keys = Object.keys(params);
    const orderedKeys = ["product", "type"].filter((key) => keys.includes(key));
    const otherKeys = keys.filter((key) => !orderedKeys.includes(key)).sort();
    const sortedKeys = [...orderedKeys, ...otherKeys];

    const sortedParams = {};
    sortedKeys.forEach((key) => {
      sortedParams[key] = params[key];
    });

    const url = new URL(window.location.origin + window.location.pathname);
    for (const [key, value] of Object.entries(sortedParams)) {
      url.searchParams.set(key, value);
    }

    window.history.replaceState({}, "", url.href);
  },
);

watch(
  [form.getErrors(), () => form.isAnalyzing()],
  debounce(async ([errors, analyzing]) => {
    let fatalErrors = errors.filter((error) => error instanceof FatalFormError);
    if (fatalErrors.length > 0) {
      form.clearCalculation();
      return;
    }
    if (analyzing) {
      return;
    }

    let formData = new URLSearchParams();
    for (const [key, value] of Object.entries(store.state.order.form)) {
      formData.append(key, value !== null ? value : "");
    }

    // TEMP fix because delivery conditions dont work without it
    if (!("size_custom_height" in store.state.order.form)) {
      formData.append("size_custom_height", "");
    }
    if (!("size_custom_width" in store.state.order.form)) {
      formData.append("size_custom_width", "");
    }

    try {
      console.log("AJAX call: calculate_article");
      const response = await fetch(window.LEGACY_URL + "/order/calculate_article.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formData,
      });

      if (!response.ok) {
        console.error(await response.text());
        form.clearCalculation();
      } else {
        form.setCalculation(await response.json());
      }
    } catch (e) {
      console.error(e);
      form.clearCalculation();
    }
  }, 200),
);

watch(
  [form.getErrors(), calculation, () => form.isAnalyzing()],
  debounce(async ([errors, calculation, analyzing]) => {
    let fatalErrors = errors.filter((error) => error instanceof FatalFormError);
    if (fatalErrors.length !== 0 || !calculation) {
      form.clearPriceDifference();
      return;
    }
    if (analyzing) {
      return;
    }

    let formData = new URLSearchParams();
    let index = 0;

    for (const [key, value] of Object.entries(store.state.order.form)) {
      formData.append(`form[${index}][name]`, key);
      formData.append(`form[${index}][value]`, value !== null ? value : "");
      index++;
    }

    const options = store.state.order.options.filter((option) => {
      return option.showPriceDiff === true;
    });
    options.forEach((option, index) => {
      formData.append(`options[${index}][name]`, option.key);
      formData.append(`options[${index}][value]`, option.value);
    });

    try {
      console.log("AJAX call: calculate_multiple_articles");
      const response = await fetch(window.LEGACY_URL + "/order/calculate_multiple_articles.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formData,
      });

      if (!response.ok) {
        console.error(await response.text());
        form.clearPriceDifference();

        return;
      }

      const prices = await response.json();
      for (const [key, item] of Object.entries(prices)) {
        const option = options[key];
        let difference = item.price_total - calculation.price_total;

        if (item.with_tax != 0) {
          difference = (item.price_total - calculation.price_total) * (1 + item.taxrate / 100);
        }
        if (difference == 77 || difference > 500 || difference < -500) {
          // uitzondering, dit betekent dat de prijs niet berekend kon worden
          difference = 0;
        }
        form.setPriceDifference({ option, difference });
      }
    } catch (e) {
      console.error(e);
      form.clearPriceDifference();
    }
  }, 200),
);

watch(
  [() => form.isInitialized(), () => form.isUploading(), () => store.state.order.changes.total],
  debounce(async ([initialized, uploading]) => {
    if (!initialized || uploading) {
      return;
    }

    let formData = new URLSearchParams();
    formData.append("f", "getFileInfo");
    formData.append("filename", form.getValue(FileNamesOption, ""));
    formData.append("fullscan", 1);

    let index = 0;
    for (const [key, value] of Object.entries(store.state.order.form)) {
      formData.append("form_data[" + index + "][name]", key);
      formData.append("form_data[" + index + "][value]", value !== null ? value : "");
      index++;
    }

    const fileInfoFailed = () => {
      form.addError(
        new FatalFormError(
          __("Je documenten zijn goed ontvangen maar konden niet worden geanalyseerd."),
        ),
      );
      form.deleteFileinfo();
    };

    try {
      console.log("AJAX call: getfileinfo");

      form.setAnalyzing(true);
      emit("set-analyzing-state", true);

      const response = await fetch(window.LEGACY_URL + "/inc/ajax.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formData,
      });

      if (!response.ok) {
        console.error(await response.text());

        return fileInfoFailed();
      }

      const data = await response.json();
      if (data?.error === "file error") {
        return fileInfoFailed();
      }

      if (form.getValue(DefaultDocOption) === 0) {
        form.setFileinfo(data);
      } else if (form.getValue(DefaultDocOption) === 1) {
        form.deleteFileinfo();
      }
    } catch (e) {
      console.error(e);

      return fileInfoFailed();
    } finally {
      form.setAnalyzing(false);
      emit("set-analyzing-state", false);
    }
  }, 200),
);
</script>

<template>
  <div class="container_12">
    <div class="custom_width content_page">
      <div class="page article article1">
        <div class="grid_9 article_header">
          <h1 v-if="component">
            {{ title }} {{ __("samenstellen") }}
            <a
              href="javascript:;"
              title="Wijzig product"
              class="prodselect lightboxbtn button grey no_arrow"
              @click="emit('cart-change-product')"
            >
              {{ __("wijzig") }}
            </a>
          </h1>
        </div>
        <div class="grid_9">
          <div class="grid_4 suffix_1 article_left">
            <form id="calcForm">
              <table class="cellspacing">
                <component :is="component" v-if="component" :product="product" />
              </table>
            </form>
            <OrderTooltip />
          </div>
          <div id="sticky-container" class="grid_4">
            <div id="follow-container">
              <div class="article_right">
                <ProcessingMessage v-if="form.isUploading()" />
                <component
                  :is="messageComponent(notification)"
                  v-for="notification in form
                    .getNotifications()
                    .filter((n) => n.position === 'top')"
                  :key="notification.id"
                  :instance="notification"
                  @close="form.removeNotification(notification)"
                />

                <div id="article_price_tabel">
                  <OrderPrices v-if="calculation" :calculation="calculation" />
                  <div id="add_to_cart">
                    <p v-if="!calculation" class="default-price-text mt-4">
                      {{ __("Prijs & leverdatum worden automatisch berekend") }}
                    </p>
                    <button
                      v-if="!form.isEditingFavorite()"
                      id="winkelmandje"
                      class="legacy-submit noproduct btn btn-secondary tailwind"
                      title="Voeg toe aan winkelmandje"
                      :disabled="submitting || form.isUploading()"
                      @click.stop="() => submit(false)"
                    >
                      <template v-if="!submitting">
                        <span>
                          <svg-use
                            :id="form.isEditing() ? 'floppy-disk' : 'cart-plus'"
                            type="solid"
                            class="#fill-white #w-[20px] #h-[20px]"
                          ></svg-use>
                        </span>
                        {{ form.isEditing() ? __("Opslaan") : __("Bestel") }}
                      </template>
                      <LoaderSpinner v-else />
                    </button>
                  </div>
                </div>
                <Preview />
                <OrderFileList />
              </div>
              <div class="fixed error-messages">
                <div v-if="! form.hasErrors()" id="article_fixed_data">
                  <span v-if="calculation" class="fixed_delivery_date">{{ calculation.delivery_data.day_text_nl }} {{ calculation.delivery_data.day }} {{ calculation.delivery_data.month_nl + (calculation.delivery_data.time != '18:00' ? ' ' + calculation.delivery_data.time : '') }}</span>
                  <span v-if="calculation" class="fixed_price">€ {{ calculation.with_tax == '0' ? formatMoney(calculation.price_total) + ' ' + __('excl.') : formatMoney(calculation.price_total_incl) + ' ' + __('incl.') }} btw</span>
                  <button
                    v-if="!form.isEditingFavorite()"
                    class="btn btn-secondary fast-submit-article"
                    title="Voeg toe aan winkelmandje"
                    :disabled="submitting || form.isUploading()"
                    @click.stop="() => submit(false)"
                  >
                    <template v-if="!submitting">
                      {{ form.isEditing() ? __("Opslaan") : __("Bestel") }}
                    </template>
                    <LoaderSpinner v-else />
                  </button>
                </div>
                <div v-if="form.hasErrors()" class="grid_12 errors errors2" style="display: block">
                  <component
                    :is="messageComponent(error)"
                    v-for="error in form.getErrors()"
                    :key="error.id"
                    :instance="error"
                    @close="form.removeError(error)"
                  />
                </div>
                <div class="grid_12 errors errors1" style="display: block">
                  <AnalyzingMessage v-if="form.isAnalyzing()" />
                  <component
                    :is="messageComponent(warning)"
                    v-for="warning in form.getWarnings()"
                    :key="warning.id"
                    :instance="warning"
                    @close="form.removeWarning(warning)"
                  />
                  <component
                    :is="messageComponent(notification)"
                    v-for="notification in form
                      .getNotifications()
                      .filter((n) => n.position === 'bottom')"
                    :key="notification.id"
                    :instance="notification"
                    @close="form.removeNotification(notifications)"
                  />
                </div>
              </div>
              <OrderDeliveryTerms
                :open="showDeliveryConditions"
                class="page-legacy"
                @close="showDeliveryConditions = false"
              ></OrderDeliveryTerms>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@media (max-width: 767px) {
  .popover {
    min-width: 300px;
  }
}
.popover-content small {
  font-size: 12px;
}
.popover-content img {
  max-width: 100%;
}
</style>
