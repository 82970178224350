<script>
import OrderOption from "../classes/OrderOption";

export const CustomWidthOption = new OrderOption({
  key: "size_custom_width",
  type: "integer",
  updateUrl: true,
});

export const CustomHeightOption = new OrderOption({
  key: "size_custom_height",
  type: "integer",
  updateUrl: true,
});
</script>
<script setup>
import { computed, watch } from "vue";
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import { FatalFormError } from "../composables/errors";
import PaperCardOption from "./PaperCardOption.vue";
import { useSetup } from "../composables/option";
import { isOneOf, isRequired } from "../checks/basic";
import { useOrderForm } from "../../modules/orderModule";
import { __, sprintf } from "../composables/lang";

defineOptions(
  new OrderOption({
    key: "size",
    value: "custom",
    label: __("eigen formaat"),
    updateUrl: true,
  }),
);

const props = defineProps(useRadioOptionProps());
const { option, check, disabled, tooltipTitle, tooltipContent } = useSetup(props);
const form = useOrderForm();

const widthId = computed(() => {
  return Symbol.keyFor(CustomWidthOption.id);
});

const heightId = computed(() => {
  return Symbol.keyFor(CustomHeightOption.id);
});

form.addOption({
  option: CustomWidthOption,
  type: "integer",
});
form.addOption({
  option: CustomHeightOption,
  type: "integer",
});

for (const option of [CustomWidthOption, CustomHeightOption]) {
  const preloaded = import.meta.env.SSR ? global.preload[option.key] : window.preload[option.key];
  const urlParamsObject = import.meta.env.SSR
    ? {}
    : Object.fromEntries(new URLSearchParams(window.location.search).entries());

  if (preloaded) {
    form.updateItem({
      option,
      value: parseInt(preloaded),
    });
    form.touchItem({ option, programmatically: true });
  } else if (!form.isEditing() && urlParamsObject[option.key]) {
    form.updateItem({
      option,
      value: parseInt(urlParamsObject[option.key]),
    });
  }
}

const update = (option, event) => {
  if (!isNaN(parseInt(event.target.value))) {
    form.updateItem({
      option,
      value: parseInt(event.target.value),
    });
  } else {
    form.updateItem({
      option,
      value: null,
    });
  }
  form.touchItem({ option });
};

check(isRequired());
check(isOneOf(props.choices));

form.addOptionCheck({
  option: CustomWidthOption,
  section: `${props.section}: ${__("breedte")}`,
  fn: (widthOption, form) => {
    if (!form.isChecked(option)) {
      return;
    }

    if (form.isEmpty(widthOption)) {
      throw new FatalFormError(__("Er moet een waarde ingevuld worden"));
    } else if (!form.isInt(widthOption)) {
      throw new FatalFormError(__("Moet een getal zijn"));
    }

    const width = form.getValue(widthOption);
    const height = form.getValue(CustomHeightOption, 0);

    let orientation = height > width || height === width ? "portrait" : "landscape";
    const min = orientation === "portrait" ? 60 : 80;
    let max = orientation === "portrait" ? 310 : 440;
    if (form.isChecked(PaperCardOption) && orientation === "landscape") {
      max = 620;
    }

    const val = form.getValue(widthOption);
    if (val < min) {
      throw new FatalFormError(sprintf(__("Is te klein, minimale breedte is %smm"), min), {
        api: `must be at least ${min}`,
      });
    } else if (val > max) {
      throw new FatalFormError(sprintf(__("Is te groot, maximale breedte is %smm"), max), {
        api: `must be at most ${max}`,
      });
    }
  },
});

form.addOptionCheck({
  option: CustomHeightOption,
  section: `${props.section}: ${__("hoogte")}`,
  fn: (heightOption, form) => {
    if (!form.isChecked(option)) {
      return;
    }

    if (form.isEmpty(heightOption)) {
      throw new FatalFormError(__("Er moet een waarde ingevuld worden"));
    } else if (!form.isInt(heightOption)) {
      throw new FatalFormError(__("Moet een getal zijn"));
    }

    const width = form.getValue(CustomWidthOption, 0);
    const height = form.getValue(heightOption);

    let orientation = height > width || height === width ? "portrait" : "landscape";
    const min = orientation === "portrait" ? 80 : 60;
    let max = orientation === "portrait" ? 440 : 310;
    if (form.isChecked(PaperCardOption) && orientation === "portrait") {
      max = 620;
    }

    const val = form.getValue(heightOption);
    if (val < min) {
      throw new FatalFormError(sprintf(__("Is te klein, minimale hoogte is %smm"), min), {
        api: `must be at least ${min}`,
      });
    } else if (val > max) {
      throw new FatalFormError(sprintf(__("Is te groot, maximale hoogte is %smm"), max), {
        api: `must be at most ${max}`,
      });
    }
  },
});

const checked = computed(() => {
  return form.isChecked(option);
});

const widthValue = computed(() => {
  return form.getValue(CustomWidthOption);
});

const heightValue = computed(() => {
  return form.getValue(CustomHeightOption);
});

watch(checked, (newValue) => {
  if (newValue) {
    form.updateItem({ option: CustomWidthOption, value: null });
    form.updateItem({ option: CustomHeightOption, value: null });
  } else {
    form.deleteItem(CustomWidthOption);
    form.deleteItem(CustomHeightOption);
  }
  form.touchItem({ option: CustomWidthOption, programmatically: true });
  form.touchItem({ option: CustomHeightOption, programmatically: true });
});
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :option="option"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
  />
  <tr v-if="checked" class="tr_size_custom_fields">
    <td width="24%"></td>
    <td width="57%">
      <div class="custom-size-field">
        <input
          :id="widthId"
          :value="widthValue"
          type="number"
          autocomplete="off"
          placeholder="b"
          style="max-width: 70px"
          @input="(event) => update(CustomWidthOption, event)"
        />
        x
        <input
          :id="heightId"
          :value="heightValue"
          type="number"
          autocomplete="off"
          placeholder="h"
          style="max-width: 70px"
          @input="(event) => update(CustomHeightOption, event)"
        />
        mm
      </div>
    </td>
    <td width="19%"></td>
  </tr>
</template>
